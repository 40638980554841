import { createLocalStorageRecentSearchesPlugin, search } from "@algolia/autocomplete-plugin-recent-searches";

export const recentSearchesPlugIn = createLocalStorageRecentSearchesPlugin({
  key: "autocomplete-two-column-layout-example",
  search(params) {
    return search({ ...params, limit: params.query ? 1 : 4 });
  },
  transformSource({ source, onTapAhead }) {
    return {
      ...source,
      templates: {
        ...source.templates,
        item(params) {
          const { html, item } = params;
          return html`
            <div
              class="aa-Item"
              onClick="${(e: MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                onTapAhead(item);
              }}"
              style="cursor: pointer;"
            >
              <span class="aa-ItemContent">
                <svg class="vc-icon shrink-0">
                  <use href="/static/thorlabs/icons/basic/arrow-rotate-right.svg?#icon"></use>
                </svg>
                <span>${item.label}</span></span
              >
            </div>
          `;
        },
      },
    };
  },
});
