<template>
  <div class="relative flex w-auto lg:w-[220px] xl:w-[300px]">
    <div id="autocomplete"></div>
  </div>
</template>

<script setup lang="ts">
import { autocomplete } from "@algolia/autocomplete-js";
import { createRedirectUrlPlugin } from "@algolia/autocomplete-plugin-redirect-url";
import { onMounted, onUnmounted, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import { useRouter } from "vue-router";
import { getFamilyPagesPlugin } from "./plugins/familyPagesPlugin";
import { getGuidesPlugin } from "./plugins/guidesPlugin";
import { getProductsPlugin } from "./plugins/productsPlugin";
import { createQuerySuggestionsPluginInstance } from "./plugins/querySuggestionPlugin";
import { recentSearchesPlugIn } from "./plugins/recentSearchesPlugin";
import { getSoftwarePagesPlugin } from "./plugins/softwarePagesPlugin";
import { getTechnicalResourcesPlugin } from "./plugins/technicalResourcesPlugin";
import type { IAlgoliaProduct } from "./searchTypes";
import type { BaseItem } from "@algolia/autocomplete-core";
import type { AutocompleteCollection } from "@algolia/autocomplete-js";

const emit = defineEmits(["search-submitted", "item-selected"]);
let ac: ReturnType<typeof autocomplete> | undefined = undefined;
const { t } = useI18n();

onUnmounted(() => {
  ac?.destroy();
});

onMounted(() => {
  const router = useRouter();
  const redirectUrlPlugin = createRedirectUrlPlugin();

  ac = autocomplete({
    container: "#autocomplete",
    plugins: [
      createQuerySuggestionsPluginInstance(),
      // @ts-expect-error - It is a valid type, but it has debounce on it
      getProductsPlugin(),
      // @ts-expect-error - It is a valid type, but it has debounce on it
      getGuidesPlugin(),
      // @ts-expect-error - It is a valid type, but it has debounce on it
      getFamilyPagesPlugin(),
      // @ts-expect-error - It is a valid type, but it has debounce on it
      getSoftwarePagesPlugin(),
      // @ts-expect-error - It is a valid type, but it has debounce on it
      getTechnicalResourcesPlugin(),
      recentSearchesPlugIn,
      redirectUrlPlugin,
    ],
    openOnFocus: true,
    stallThreshold: 400,
    detachedMediaQuery: "none",
    placeholder: "Search",
    render({ elements, render, html, state }, root) {
      const {
        querySuggestionsPlugin,
        recentSearchesPlugin,
        softwarePages,
        technicalResources,
        products,
        guides,
        familyPages,
      } = elements;

      const productItemsCollection = state.collections.find((collection) => collection.source.sourceId === "products");
      const hideOtherSources =
        productItemsCollection?.items.length === 1 &&
        (productItemsCollection?.items[0] as unknown as IAlgoliaProduct).hideOtherSources === true;

      const template = html`<div>
        <div class="aa-PanelLayout aa-Panel--scrollable">
          <div class="aa-PanelSections">
            <div class="aa-PanelSection--left">
              <div class="aa-PanelSectionSources">
                <h6>${t("pages.search.recent_searches")}</h6>
                ${recentSearchesPlugin}
              </div>
              <div class="aa-PanelSectionSources">
                <h6>${t("pages.search.suggestions")}</h6>
                ${querySuggestionsPlugin}
              </div>
              ${hideOtherSources || isEmptySoruce(state.collections, "technicalResources")
                ? ""
                : html` <div class="aa-PanelSection--tech-resources">
                    <div class="aa-PanelSectionSource">${technicalResources}</div>
                  </div>`}
              ${hideOtherSources || isEmptySoruce(state.collections, "softwarePages")
                ? ""
                : html` <div class="aa-PanelSection--soft-dwd">
                    <div class="aa-PanelSectionSource">${softwarePages}</div>
                  </div>`}
            </div>
            <div class="aa-PanelSection--right">
              ${hideOtherSources || isEmptySoruce(state.collections, "familyPages")
                ? ""
                : html` <div class="aa-PanelSection--fp">
                    <div class="aa-PanelSectionSource">${familyPages}</div>
                  </div>`}
              ${isEmptySoruce(state.collections, "products")
                ? ""
                : html` <div class="aa-PanelSection--products">
                    <div class="aa-PanelSectionSource">${products}</div>
                  </div>`}
              ${hideOtherSources || isEmptySoruce(state.collections, "guides")
                ? ""
                : html` <div class="aa-PanelSection--guides">
                    <div class="aa-PanelSectionSource">${guides}</div>
                  </div>`}
            </div>
          </div>
        </div>
      </div>`;

      // @ts-expect-error - It is assignable to VNodeChild
      render(template, root);
    },
    onSubmit({ state, event }) {
      event.preventDefault();
      const searchQuery = state.query;
      router.push({ name: "Search", query: { q: searchQuery } });
      // Emit an event to notify the parent component
      emit("search-submitted");
      // Explicitly close the autocomplete dropdown
      ac?.setIsOpen(false);
    },
  });
});

function isEmptySoruce(collections: AutocompleteCollection<BaseItem>[], name: string): boolean {
  const collection = collections.find((col) => col.source.sourceId === name);
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (!collection || !collection?.items || collection.items.length === 0) {
    return true;
  }

  return false;
}
</script>

<style lang="scss">
#autocomplete {
  --aa-muted-color-rgb: --color-neutral-a3;
  --aa-primary-color-rgb: --color-neutral-a3;
  --aa-muted-color-alpha: 0.3;

  @apply w-full;
  .aa-Form {
    @apply flex items-center bg-[color:var(--color-neutral-a6)] border border-[color:var(--color-neutral-a3)] text-[color:var(--color-neutral-a1)] rounded-[4px] focus-within:border-black focus-within:outline-none focus-within:ring-0;
  }
  .aa-InputWrapper {
    @apply flex-grow pl-4 order-1;
  }
  .aa-InputWrapperSuffix {
    @apply order-2;
    button {
      @apply relative after:absolute after:content-[''] after:w-[1px] after:h-[15px] after:bg-[--color-neutral-a3] after:top-[50%] after:-translate-y-1/2 after:right-0 after:opacity-100 hover:text-[--color-primary-a1];
      &[hidden] {
        @apply after:opacity-0;
      }
    }
  }
  .aa-InputWrapperPrefix {
    order: 3;
    button {
      @apply hover:text-[--color-primary-a1];
    }
  }
}
.aa {
  &-Panel {
    box-shadow: 0px 8px 16px 0px rgba(35, 38, 59, 0.25);
    @apply z-[12] mt-4 lg:mt-[20px] rounded-none lg:rounded-b-md pt-5 lg:pt-6 px-4 lg:px-5 py-[26px] w-full lg:w-auto;
    @apply lg:left-auto lg:max-w-[76dvw] #{!important};

    @media (max-width: theme("screens.md-max")) {
      @apply left-0 right-0 #{!important};
    }

    &--scrollable {
      @apply max-h-[calc(100dvh-188px)] lg:max-h-[80vh];
    }
  }

  &-PanelLayout {
    @apply lg:max-h-[80vh];
  }
  &-PanelSections {
    @apply grid lg:grid-cols-[auto_auto];

    @media (min-width: theme("screens.lg")) {
      grid-template-areas: "left right";
      gap: 30px;
    }

    .aa-PanelSection--left {
      grid-template-areas: "left";
      @apply lg:w-60;

      @media screen and (min-width: theme("screens.lg")) {
        > div:not(:last-child) {
          @apply border-b border-b-[--color-neutral-a4] pb-4 mb-5;
        }
      }
      @media screen and (max-width: theme("screens.md-max")) {
        > div {
          @apply border-b border-b-[--color-neutral-a4] pb-4 mb-5;
        }
      }
    }
    .aa-PanelSection--right {
      @apply flex flex-col lg:gap-5 lg:max-w-[768px];
      grid-template-areas: "right";

      .aa-List {
        @apply flex flex-col gap-5 lg:gap-4 pb-0;
      }

      @media screen and (max-width: theme("screens.md-max")) {
        @apply gap-5;
        > div[class*="aa-PanelSection"]:not(:last-child) {
          @apply border-b border-b-[--color-neutral-a4] pb-4;
        }
      }
      &:not(:has(div[class*="aa-PanelSection"] + div[class*="aa-PanelSection"])) {
        .aa-List {
          .aa-Item {
            &:last-child {
              @apply border-0;
            }
          }
        }
      }
    }
  }
  &-PanelSectionSources {
    h6 {
      @apply text-[--color-neutral-a2] text-base font-medium mt-0 mb-1 uppercase tracking-[1px];
    }
  }
  &-SourceHeader {
    @apply m-0;
    h6 {
      @apply gap-1 flex text-[--color-neutral-a2] text-base items-center font-medium m-0 uppercase tracking-[1px];
      svg {
        @apply inline-flex size-4;
      }
    }
    .search-bar__header {
      @apply flex justify-between gap-5 items-center;
      > a {
        @apply text-[--color-link] text-base font-medium hover:text-[--color-link-hover];
      }
    }
  }
  &-List {
    @apply pt-4 flex flex-col gap-4 pb-2;
  }
  &-Item {
    @apply p-0 block min-h-0;
    .aa-ItemContent {
      @apply flex items-start;
      &--link {
        @apply text-[--color-link] hover:text-[--color-link-hover];
      }
      svg {
        @apply text-[--color-neutral-a2] size-4 mt-0.5;
      }
    }
    &[aria-selected="true"] {
      @apply bg-transparent;
    }
  }
  &-PanelSection--fp {
    order: 2;
    .aa-Item {
      @apply p-0;
      .family-match {
        &--item {
          @apply flex gap-5 items-center w-full lg:border-b lg:border-[--color-neutral-a4] lg:pb-4;
        }
        &--image {
          @apply hidden lg:flex items-center justify-center w-[184px] h-[112px] border border-[--color-neutral-a4] rounded-md overflow-hidden shrink-0;
          box-shadow: 0px 4px 10px 0px rgba(223, 223, 223, 0.25);
          img {
            @apply max-w-full max-h-full h-auto object-contain rounded-md overflow-hidden;
          }
        }
        &--content {
          @apply flex flex-col gap-1;
          &-wrapper {
            @apply flex xl:flex-nowrap flex-wrap w-full gap-5 xl:items-center;
          }
        }
        &--name {
          @apply text-base font-bold;
        }
        &--info {
          @apply text-sm font-medium text-[--color-neutral-a2] flex gap-2;
        }
        &--link {
          @apply hidden lg:block font-semibold px-6 py-3.5 border border-[--color-neutral-a3] rounded-md text-[--color-neutral-a1] hover:border-[--color-neutral-a2] whitespace-nowrap ml-auto;
        }
        &--title {
          @apply text-[--color-neutral-a2] text-base font-medium;
        }
      }
      &:last-child {
        .family-match--item {
          @apply lg:pb-[30px];
        }
      }
    }
  }
  &-PanelSection--products {
    order: 3;
    .aa-Item {
      @apply p-0;
      .product-match {
        &--item {
          @apply flex gap-3 lg:gap-5 items-center w-full lg:border lg:border-[--color-neutral-a4] lg:px-5 lg:py-4 lg:rounded-md;
        }
        &--image {
          @apply flex items-center justify-center w-[80px] h-[80px] shrink-0 lg:border-0 lg:rounded-none border border-[--color-neutral-a4] rounded-md overflow-hidden;

          img {
            @apply max-w-full max-h-full h-auto object-contain;
          }
        }
        &--content {
          @apply flex flex-col gap-2;
        }
        &--name {
          @apply text-base font-bold flex items-center gap-2.5;
        }
        &--info {
          @apply font-medium;
        }
        &--link {
          @apply text-[--color-link] hover:text-[--color-link-hover] font-medium flex items-center gap-1.5;
          svg {
            @apply size-4 fill-[--color-link] hover:fill-[--color-link-hover];
          }
        }
        &--addToCart {
          @apply border-[--color-neutral-a1] rounded-md text-[--color-neutral-a1] px-5 py-2.5 flex items-center gap-1.5 ml-auto hover:border-black hover:text-black;
          @apply border border-solid #{!important};
          svg {
            @apply size-5;
          }
        }
      }
      @media screen and (min-width: theme("screens.lg")) {
        &:last-child {
          @apply border-b border-[--color-neutral-a4];
          .product-match--item {
            @apply mb-[30px];
          }
        }
      }
    }
    .exact-match {
      @apply border border-[--color-neutral-a4] rounded-full overflow-hidden text-xs font-medium text-[--color-neutral-a2] px-2.5 py-[3px] flex items-center gap-1;
    }
  }
  &-PanelSection--guides {
    order: 4;
    .aa-List {
      @apply grid gap-0 lg:gap-4 pt-2 lg:pt-2.5 #{!important};
      @apply lg:grid-cols-3 lg:gap-[30px];
    }
    .aa-Item {
      @media screen and (min-width: theme("screens.lg")) {
        @apply items-stretch grid rounded-md border  border-[--color-neutral-a4] overflow-hidden;
        grid-row: span 2;
        grid-template-rows: subgrid;
        box-shadow: 0px 4px 10px 0px rgba(223, 223, 223, 0.25);
      }
    }
    .guide-match {
      &--item {
        @apply grid justify-between;
      }
      &--content {
        @apply py-3 lg:p-3 lg:text-13 font-medium lg:font-semibold;
      }
      &--image {
        @apply hidden lg:flex aspect-[1.6] max-h-[140px] w-full border-t-[1px] border-t-[--color-neutral-a4] mt-auto overflow-hidden;
        img {
          @apply max-w-full max-h-full h-auto object-contain;
        }
      }
    }
  }
  &-PanelSection--soft-dwd {
    .aa-SourceHeader {
      h6 {
        svg {
          @apply inline-flex size-5;
        }
      }
    }
  }
}

/* custom scss programming */
.aa-PanelSection--products:has(.exact-match) {
  order: 1;
}
.aa-PanelSection--products:has(.discontinued) {
  order: 1;
}
.discontinued {
  &-item {
    @media screen and (min-width: theme("screens.lg")) {
      @apply flex flex-col gap-1.5 max-w-[300px] border border-[--color-neutral-a4] p-4 rounded-md;
    }
    @media screen and (max-width: theme("screens.md-max")) {
      @apply flex flex-row-reverse gap-3 items-center;
    }
    &--wrapper {
      @apply flex flex-col gap-1.5;
    }
    &--status {
      @apply text-[--color-neutral-a1] text-base font-semibold border-l-[2px] border-l-[--color-primary-a1] pl-2.5 mb-4;
      > a {
        @apply ml-1 text-[--color-link] hover:text-[--color-link-hover];
      }
    }
    &--info {
      @apply flex gap-2 items-center flex-wrap;
    }
    &--name {
      @apply text-base font-bold;
    }
    &--badge {
      @apply inline-flex bg-[--color-primary-a1] text-white font-bold text-xs px-2.5 py-1.5 rounded-full;
    }
    &--details {
      @apply text-sm lg:text-base font-medium;
    }
    &--image {
      @apply flex items-center justify-center w-[80px] h-[80px] aspect-square lg:aspect-auto lg:w-[268px] lg:h-[120px] mt-auto border border-[--color-neutral-a4] rounded-md overflow-hidden lg:border-none lg:rounded-none shrink-0;
      img {
        @apply max-w-full max-h-full h-auto object-contain;
      }
    }
  }
  &-group--wrapper {
    @apply flex gap-4 flex-col lg:flex-row items-start lg:items-stretch;
  }
}
/* ANA check right position for dropdown */
.aa-Panel--scrollable {
  @apply py-0 pl-0 pr-0 lg:pr-3;
}
</style>
