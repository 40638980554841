import { algoliasearch } from "algoliasearch";

export const initializeSearchClient = (environment: string) => {
  if (environment == "prod") {
    const appId = "TDDBKZ98JB";
    const searchKey = "d730c026eb67ae7459d47c54955d3b3e";
    return algoliasearch(appId, searchKey);
  }

  const testingAppId = "testingG1RNTD73ZV";
  const testingSearchKey = "0d4a7acebc56b7245e761171893be887";
  return algoliasearch(testingAppId, testingSearchKey);
};

export const getIndexName = (env: string, indexType: string, language: string) => {
  const prefix = env === "prod" ? "" : `${env}_`;
  return `${prefix}${indexType}_${language}`;
};

export const formatDate = (date: Date | string) => {
  if (!date) {
    return "";
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dateObject = date instanceof Date ? date : new Date(date);
  return dateObject.toLocaleDateString("en-US", options);
};
