import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { searchClient } from "./searchClient";

export function createQuerySuggestionsPluginInstance() {
  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const productsIndexName = `${environment}_${savedLocale.value}_query_suggestions`;

  return createQuerySuggestionsPlugin({
    searchClient,
    indexName: productsIndexName,
    transformSource({ source, onTapAhead }) {
      return {
        ...source,
        templates: {
          ...source.templates,
          item(params) {
            const { html, item, components } = params;
            return html`
              <div
                class="aa-Item"
                onClick="${(e: MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onTapAhead(item);
                }}"
                style="cursor: pointer;"
              >
                <span class="aa-ItemContent"
                  ><span>${components.ReverseHighlight({ hit: item, attribute: "query" })}</span></span
                >
              </div>
            `;
          },
        },
      };
    },
  });
}
