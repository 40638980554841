import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { useI18n } from "vue-i18n";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { getIndexName } from "@/shared/thorlabs/search";
import { searchClient } from "./searchClient";
import type { IAlgoliaFamilyPage } from "../searchTypes";

export function getTechnicalResourcesPlugin() {
  const { t } = useI18n();

  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const technicalResourcesIndexName = getIndexName(environment, "familypages", savedLocale.value);

  return {
    getSources({ query }: { query: string }) {
      if (!query) {
        return [];
      }
      return [
        {
          sourceId: "technicalResources",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: technicalResourcesIndexName,
                  params: {
                    query,
                    hitsPerPage: 3,
                    attributesToSnippet: ["name:10"],
                    snippetEllipsisText: "…",
                    filters: "isTechnicalResource:true",
                  },
                },
              ],
            });
          },
          templates: {
            header({ html }: { html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string }) {
              return html`<h6>
                <svg class="vc-icon shrink-0">
                  <use href="/static/thorlabs/icons/basic/file-alt-rotate.svg?#icon"></use>
                </svg>
                ${t("autocomplete.search.technical_resources")}
              </h6>`;
            },
            item({
              item,
              html,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              item: IAlgoliaFamilyPage;
            }) {
              return html`<span class="aa-ItemContent aa-ItemContent--link">
                <a href=${item.url}>${item.name}</a>
              </span>`;
            },
            noResults() {
              return t("autocomplete.search.empty_technical_resources");
            },
          },
        },
      ];
    },
  };
}
