import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import type { Product } from "@/core/api/graphql/types";

const { currentLanguage } = useLanguages();

export const getFileExtension = (filename: string | undefined) => {
  if (!filename) {
    return "";
  }
  return filename.split(".").pop()?.split("?")[0] ?? "";
};

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const getRelativeUrl = (url: string) => {
  try {
    const urlObject = new URL(url);
    return urlObject.pathname + urlObject.search;
  } catch (err) {
    return url;
  }
};

export const getUrl = (url: string) => {
  if (!url) {
    return "#";
  }

  const { optimizelyHost } = globals;
  if (optimizelyHost == null || optimizelyHost === "") {
    return url;
  }

  if (url.startsWith(optimizelyHost)) {
    return getRelativeUrl(url);
  }

  return url;
};

export const getUrlHash = () => {
  let hash = window.location.hash;

  // Check if the hash is not empty
  if (hash) {
    // Remove the '#' character
    hash = hash.substring(1);

    // Decode any percent-encoded characters
    return decodeURIComponent(hash);
  }

  return undefined;
};

export const getItemUrl = (product: Product | undefined) => {
  // If both parameters are undefined, return current window location
  if (!product) {
    return window.location.href;
  }

  // Get current domain
  const currentDomain = window.location.origin;
  const url = product?.descriptionsWithFallbacksTL.find((d) => d.reviewType === "ItemUrl")?.content;
  const sku = product?.code;

  // Check if the URL is valid and contains "/item/"
  if (url && url.includes("/item/")) {
    return url;
  }

  // Determine language segment if available
  const languageSegment = currentLanguage?.value?.twoLetterLanguageName
    ? `/${currentLanguage.value.twoLetterLanguageName}`
    : "";

  // If URL is empty or doesn't match, format productCode and return new URL
  const formattedProductCode = skuFormatter(sku);
  return `${currentDomain}${languageSegment}/item/${formattedProductCode}`;
};

export const skuFormatter = (sku: string) => sku.replace(/\//g, "_");

export const getBrightness = (hexColor: string) => {
  const rgb = parseInt(hexColor.slice(1), 16); // Convert hex to RGB
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  // Calculate brightness (YIQ formula)
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const stripHtml = (html: string | undefined) => {
  if (!html) {
    return;
  }
  return html.replace(/<\/?[^>]+(>|$)/g, "");
};
