<template>
  <Dialog
    ref="dialogRef"
    dialog-mode="medium"
    content-class="dialog-content"
    header-class="font-semibold text-[20px]"
    class="dialog-min-height"
    @close="resetForm"
  >
    <template #title>{{ dialogTitle ?? formContainer?.properties.title }}</template>
    <template #content>
      <div v-if="!formContainer" class="flex min-h-[400px] items-center justify-center">
        <VcIcon
          name="loading-3quarters"
          path="/static/thorlabs/icons/family-page/"
          size="xxl"
          class="spin-animation fill-[--color-neutral-a2]"
        />
      </div>
      <div v-else :class="descriptionClass">
        <slot name="description"></slot>
        <Form
          ref="formRef"
          :key="formKey"
          :form="formContainer"
          :product="product"
          :mail-to="mailTo"
          @submitted="closeDialog"
        />
      </div>
    </template>
    <template #footer>
      <VcButton color="primary" variant="solid" class="w-full" size="lg" @click="dialogRef && submitForm()">{{
        dialogButton ?? $t("common.buttons.submit")
      }}</VcButton>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useForm } from "@/shared/common/composables/useForm";
import Form from "./form.vue";
import type { ExtendedFormContainer } from "../types";
import type { Product } from "@/core/api/graphql/types";
import Dialog from "@/shared/thorlabs/shared/components/dialog-modal.vue";
import VcIcon from "@/ui-kit/components/atoms/icon/vc-icon.vue";

interface IProps {
  formName: string;
  product?: Product;
  dialogTitle?: string;
  dialogButton?: string;
  descriptionClass?: string;
  mailTo?: string;
}

const props = defineProps<IProps>();

const dialogRef = ref<InstanceType<typeof Dialog> | null>(null);
//this is used to remount the component after submit/close
const formKey = ref(0);

const formContainer = ref<ExtendedFormContainer | undefined>(undefined);
const formRef = ref<InstanceType<typeof Form> | null>(null);

const showDialog = () => {
  if (!formContainer.value) {
    const { formContainer: useFormContainer } = useForm(props.formName);
    watch(
      useFormContainer,
      (newValue) => {
        formContainer.value = newValue;
      },
      { immediate: true },
    ); // Ensure the watcher is triggered immediately
  }
  dialogRef.value?.openDialog();
};

function closeDialog(): void {
  dialogRef.value?.closeDialog();
}

function submitForm(): void {
  formRef.value?.submit();
}

function resetForm(): void {
  formKey.value++;
}

defineExpose({
  showDialog,
});
</script>

<style scoped>
.dialog-min-height {
  min-height: 400px;
}
</style>
