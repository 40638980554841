<template>
  <div class="product-info">
    <div class="product-info__container">
      <div class="product-info__side">
        <ImageGallery :images="product.images"></ImageGallery>
        <div class="product-info__print">
          <VcImage :src="product.imgSrc" class="product-info__print-img" />
        </div>
        <component
          :is="ProductBlocks['product-image-buttons']"
          v-if="ProductBlocks['product-image-buttons']"
          :cad-pdf="cadPdfFile"
          :family-page-url="
            product.itemStatusTL === 'Superseded' || product.itemStatusTL === 'Discontinued'
              ? null
              : product.descriptionsWithFallbacksTL.find((d) => d.reviewType === 'FamilyPageUrl')?.content
          "
          :e-drawing="eDrawingFile"
          :product-code="product.code"
          class="hidden md:flex"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { ImageGallery } from "@/shared/catalog";
import ProductBlocks from "@/shared/catalog/components/product";
import type { PageContent } from "../types";
import type { Product } from "@/core/api/graphql/types";

interface IProps {
  product: Product;
  model: PageContent;
}

const props = defineProps<IProps>();

const cadPdfFile = computed(() => {
  if (props.product.assets.filter((d) => d.group?.toLocaleLowerCase().includes("cad pdf")).length === 1) {
    return props.product.assets.find((d) => d.group?.toLocaleLowerCase().includes("cad pdf"));
  }

  return undefined;
});

const eDrawingFile = computed(() => {
  if (props.product.assets.filter((d) => d.group?.toLocaleLowerCase().includes("edrawing")).length === 1) {
    return props.product.assets.find((d) => d.group?.toLocaleLowerCase().includes("edrawing"));
  }

  return undefined;
});
</script>

<style lang="scss">
.product-info {
  @apply print:min-h-full print:flex print:w-full print:items-center print:justify-center;

  &__container {
    @apply flex flex-col lg:flex-row lg:gap-8 print:flex-row print:gap-4;
  }

  &__side {
    @apply flex-none w-[100%];
  }

  &__videos {
    @apply mt-8 lg:mt-3;
  }

  &__print {
    @apply hidden w-full flex-none print:flex print:justify-center print:items-center;
  }

  &__print-img {
    @apply max-w-full;
  }

  &__content {
    @apply mt-5 flex flex-col gap-6 lg:mt-0 lg:grow print:mt-5 print:grow;
  }
}
</style>
