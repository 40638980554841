<template>
  <nav
    v-show="mobileMenu"
    ref="mobileMenuElement"
    class="absolute left-0 top-full z-20 flex w-full flex-col bg-[color:var(--color-mobile-menu-bg)] text-[color:var(--color-mobile-menu-link)]"
  >
    <!-- region Main menu section -->
    <section
      class="mobile-menu grow overflow-y-auto border-b border-b-[color:var(--color-neutral-a4)] bg-[color:var(--color-neutral-a6)] pb-8"
    >
      <template v-for="(item, index) in items" :key="index">
        <div class="bg-[color:var(--color-neutral-a6)]">
          <div class="px-4">
            <button
              type="button"
              class="main-nav-item flex w-full items-center justify-between border-b border-b-[color:var(--color-neutral-a4)] py-[9px] text-left text-18 text-[--color-neutral-a1]"
              :class="{
                'border-b-[color:var(--color-neutral-a6)] font-bold text-[--color-primary-a1] [&_svg]:text-[color:var(--color-primary-a1)]':
                  activeItem === index,
              }"
              @click="handleToggle(index, item.items.length > 0, item)"
            >
              <div class="flex items-center" :class="{ 'active-item': activeItem === index }">
                {{ item.label }}
                <VcImage v-if="item.icon" class="ml-[2px] size-[18px] max-w-none" :src="item.icon" />
              </div>
              <VcIcon
                v-if="item.items.length > 0"
                class="stroke-[color:var(--color-neutral-a6)] stroke-2 text-[--color-neutral-a1]"
                :name="activeItem === index ? 'chevron-up' : 'chevron-down'"
                :size="14"
              />
            </button>
          </div>
          <div v-show="activeItem === index && item.items.length > 0" class="bg-white px-8 pb-1 text-left text-18">
            <template v-for="(subItem, subIndex) in item.items" :key="subIndex">
              <div v-if="subItem.separator" class="my-2 h-px w-8 bg-[--color-neutral-a4]"></div>
              <div v-if="!subItem.url" class="flex items-center py-[9px] font-bold">
                <VcImage
                  v-if="subItem.icon"
                  class="mr-2 size-[20px] max-w-none shrink-0 text-[--color-neutral-a1]"
                  :src="subItem.icon"
                />
                <div v-if="subItem.label" :style="`color: ${subItem.color ? subItem.color : ''}`">
                  {{ subItem.label }}
                </div>
              </div>
              <a
                v-else
                :href="navigate(item.label, subItem.url)"
                class="flex items-center py-[9px]"
                @mouseover="activeSubMenu(subIndex)"
                @focusin="activeSubMenu(subIndex)"
              >
                <VcImage
                  v-if="subItem.icon && item.menuTemplate === 'three-columns'"
                  class="mr-2 size-[20px] max-w-none shrink-0 text-[--color-neutral-a1]"
                  :src="subItem.icon"
                />
                <div
                  v-if="subItem.label"
                  class="flex items-center border-b-2 border-transparent"
                  :style="{ borderColor: subItem.active ? subItem.color : '' }"
                >
                  {{ subItem.label }}
                </div>
              </a>
              <div v-if="subItem.items.length > 0" class="ml-5">
                <template v-for="(subSubItem, subSubIndex) in subItem.items" :key="subSubIndex">
                  <a :href="subSubItem.url" class="flex items-center py-[9px]">
                    <div v-if="subSubItem.label" :style="`color: ${subSubItem.color ? subSubItem.color : ''}`">
                      {{ subSubItem.label }}
                    </div>
                  </a>
                </template>
              </div>
            </template>
            <template v-if="item.menuTemplate === 'three-columns'">
              <a :href="item.url" class="group/all-menu flex items-center py-[9px]">
                <VcIcon class="mr-1 mt-[2px] shrink-0 text-[--color-neutral-a1]" name="grid" />
                <span
                  :data-title="$t('shared.layout.header.menu.view_all_products')"
                  class="before:block before:h-0 before:font-semibold before:opacity-0 before:content-[attr(data-title)] group-focus/all-menu:font-semibold"
                  >{{ $t("shared.layout.header.menu.view_all_products") }}</span
                >
              </a>
            </template>
          </div>
        </div>
      </template>
    </section>
    <!-- endregion Main menu section -->
  </nav>
</template>

<script setup lang="ts">
import { onClickOutside } from "@vueuse/core";
import { onMounted, ref, onUpdated, defineEmits, watch } from "vue";
import { useNavigations } from "@/core/composables";
import { mapGraphQLDataToMenuItems } from "@/shared/thorlabs/blocks";
import type { IMenuItem, IMobileMenuItem } from "@/shared/thorlabs/blocks";

interface Props {
  mobileMenuItems?: IMobileMenuItem[];
}

const emit = defineEmits<{
  closeMenu: [];
}>();
const props = defineProps<Props>();
const activeSubItem = ref<number | 0>(0);
const mobileMenu = ref(true);

const activeItem = ref<number | null>(null);
const items = ref<IMenuItem[]>();

const handleToggle = (itemId: number, hasChildren: boolean, item: IMenuItem) => {
  if (!hasChildren) {
    window.location.href = appendRouteToBaseUrl(item.url);
    return false;
  }
  activeItem.value = activeItem.value === itemId ? null : itemId;
};

const { mobilePreSelectedMenuItem, selectMenuItem, goMainMenu } = useNavigations();

const mobileMenuElement = ref<HTMLElement | null>(null);

onClickOutside(mobileMenuElement, () => {
  mobileMenu.value = false;
  emit("closeMenu");
});

const setMaxHeight = () => {
  const headerElement = document.querySelector(".header");
  let bannerHeight = 0;
  if (headerElement && mobileMenuElement.value) {
    const headerHeight = headerElement.getBoundingClientRect().height;
    const bannerElementTop = document.getElementById("bannerAlertHeaderTop");
    if (bannerElementTop) {
      bannerHeight = bannerElementTop.getBoundingClientRect().height;
    }
    const viewportHeight = window.innerHeight;
    if (mobileMenuElement.value) {
      mobileMenuElement.value.style.maxHeight = `${viewportHeight - headerHeight - bannerHeight}px`;
    }
  }
};
onMounted(() => {
  goMainMenu();

  if (mobilePreSelectedMenuItem.value) {
    selectMenuItem(mobilePreSelectedMenuItem.value);
  }
  setMaxHeight();
});
onUpdated(() => {
  setMaxHeight();
});

window.addEventListener("resize", setMaxHeight);

watch(
  () => props.mobileMenuItems,
  (newValue) => {
    if (newValue) {
      const mappedMenuItems = mapGraphQLDataToMenuItems(newValue, true);
      items.value = mappedMenuItems;
      updateActiveMenuItem(items.value);
    }
  },
  { immediate: true },
);
function updateActiveMenuItem(menuItems: IMenuItem[]) {
  const currentUrl = window.location.pathname;

  menuItems.forEach((menuItem) => {
    // Check the main menu item
    menuItem.active = isActiveUrl(currentUrl, menuItem.url);

    // Check sub items in items array
    if (menuItem.items) {
      menuItem.items.forEach((subItem) => {
        subItem.active = isActiveUrl(currentUrl, subItem.url);
      });
    }

    // Check sub items in rightColumnItems array
    if (menuItem.rightColumnItems) {
      menuItem.rightColumnItems.forEach((subItem) => {
        subItem.active = isActiveUrl(currentUrl, subItem.url);
      });
    }
  });
}

function isActiveUrl(currentUrl: string, itemUrl: string): boolean {
  // Ensure itemUrl starts with a slash
  if (!itemUrl.startsWith("/")) {
    itemUrl = "/" + itemUrl;
  }

  // Check for exact match or match followed by a slash or end of string
  const regex = new RegExp(`^${itemUrl}(/|$)`);
  return regex.test(currentUrl);
}

function appendRouteToBaseUrl(route: string) {
  const baseUrl = `${window.location.protocol}//${window.location.host}/`;

  if (route.startsWith("/")) {
    route = route.substring(1);
  }

  return `${baseUrl}${route}`;
}

function activeSubMenu(subIndex: number) {
  activeSubItem.value = subIndex;
}

function navigate(label: string, url: string) {
  if (label === "Rapid Order") {
    const chuncks = url.split("/");
    const baseUrl = window.location.origin;
    return `${baseUrl}/cart?rapidOrder=${chuncks[chuncks.length - 2]}`;
  }

  return url;
}
</script>

<style type="scss">
.main-nav-item .active-item {
  img {
    filter: brightness(0) saturate(100%) invert(11%) sepia(100%) saturate(6243%) hue-rotate(10deg) brightness(92%)
      contrast(118%) !important;
  }
}

.mobile-menu-open {
  @apply before:min-h-dvh before:h-full before:w-full before:content-[''] before:bg-black before:absolute before:left-0 before:bottom-0 before:z-[11] before:opacity-50;
}
</style>
