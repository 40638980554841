import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { useI18n } from "vue-i18n";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { getIndexName } from "@/shared/thorlabs/search";
import { searchClient } from "./searchClient";
import type { IAlgoliaVisNavPage } from "../searchTypes";

export function getGuidesPlugin() {
  const { t } = useI18n();

  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const visNavIndexName = getIndexName(environment, "visnavs", savedLocale.value);

  return {
    getSources({ query }: { query: string }) {
      if (!query) {
        return [];
      }
      return [
        {
          sourceId: "guides",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: visNavIndexName,
                  params: {
                    query,
                    hitsPerPage: 3,
                    attributesToSnippet: ["name:10"],
                    snippetEllipsisText: "…",
                  },
                },
              ],
            });
          },
          templates: {
            header({ html }: { html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string }) {
              return html`<h6>${t("autocomplete.search.top_3_guides")}</h6>`;
            },
            item({
              item,
              html,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              item: IAlgoliaVisNavPage;
            }) {
              return html`
                <a href="${item.url}" class="guide-match--item">
                  <div class="guide-match--content">${item.name}</div>
                  <div class="guide-match--image">
                    <img src="${item.imageUrl}" width="224" height="140" alt="" role="presentation" />
                  </div>
                </a>
              `;
            },
            noResults() {
              return t("autocomplete.search.top_3_guides");
            },
          },
        },
      ];
    },
  };
}
