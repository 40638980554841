<template>
  <div class="tl-table tl-table--striped product-variations border-0">
    <table v-if="!allItemsUnavailable">
      <thead>
        <tr>
          <th>{{ $t("shared.catalog.product_details.product_variations.serial_number") }}</th>
          <th class="min-w-60">{{ $t("shared.catalog.product_details.product_variations.description") }}</th>
          <th class="!text-center">{{ $t("shared.catalog.product_details.product_variations.data_sheet") }}</th>
          <th class="!text-center">{{ $t("shared.catalog.product_details.product_variations.availability") }}</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="variation in [...product.variations]" :key="variation.code">
          <td>{{ variation.code as string }}</td>
          <td v-dompurify-html="getVariationDescription(variation.name, variation.code)"></td>
          <td class="!text-center">
            <a
              v-if="variation.dataSheetUrlTL"
              :href="variation.dataSheetUrlTL"
              :download="
                isPDF(variation.dataSheetUrlTL as string) ? false : getFileName(variation.dataSheetUrlTL as string)
              "
              target="_blank"
              rel="noopener noreferrer"
              class="product-variations__icon"
              @click.prevent="handleLinkClick(variation.dataSheetUrlTL as string)"
            >
              <VcIcon name="document-text" size="md" aria-hidden="true" />
            </a>
          </td>
          <td class="!text-center">
            <template
              v-if="
                availabilityData
                  .find((a) => a.productId === variation.code)
                  ?.availabilityResponse?.availability.toLocaleLowerCase() !== 'none'
              "
            >
              {{ availabilityData.find((a) => a.productId === variation.code)?.availabilityResponse?.availability }}
            </template>
            <template v-else>
              <VcButton
                variant="link"
                size="lg"
                class="mr-auto !font-medium"
                @click="requestLeadTimeDialogFormRef?.showDialog"
                >{{ $t("shared.product.request_lead_time") }}
              </VcButton>
            </template>
          </td>
          <td>
            <AddToCart :product="variation" has-variations hide-quantity />
          </td>
        </tr>
      </tbody>
    </table>
    <template v-if="allItemsUnavailable">
      <div class="min-h-36 content-center justify-items-center rounded-lg px-8 text-center">
        <p class="text-[--color-neutral-a2]">
          {{ $t("pages.product.no_units_available") }}
          <VcButton
            variant="link"
            size="lg"
            class="!inline-flex !text-base !font-medium"
            @click="requestLeadTimeDialogFormRef?.showDialog"
            >{{ $t("pages.product.lead_times") }}</VcButton
          >.
        </p>
      </div>
    </template>
  </div>

  <DialogForm ref="requestLeadTimeDialogFormRef" form-name="RequestLeadTimeForm" :product="product">
    <template #description>
      <ProductFormContact :subtitle="$t('shared.product.contact_form.lead_time_inquiry_subtitle', [product.code])" />
    </template>
  </DialogForm>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import { getMultipleItemsAvailability } from "@/core/api/graphql/account/queries/getMultipleItemsAvailability";
import { useCurrency } from "@/core/composables/useCurrency";
import { AddToCart } from "@/shared/cart";
import ProductFormContact from "./product-form-contact.vue";
import type { Product, ProductAvailabilityResponseType, VariationTypeExtension } from "@/core/api/graphql/types";
import DialogForm from "@/shared/forms/components/dialog-form.vue";

interface IProps {
  product: Product;
}

const props = defineProps<IProps>();
const { currentCurrency } = useCurrency();

const availabilityData = ref<ProductAvailabilityResponseType[] | []>([]);
const requestLeadTimeDialogFormRef = ref<InstanceType<typeof DialogForm> | null>(null);

//check if all items are unavailable
const allItemsUnavailable = computed(
  () =>
    availabilityData.value.filter(
      (a) => a.availabilityResponse?.availability.toLocaleLowerCase() === "none" && a.productId !== props.product.code,
    ).length === props.product.variations.length || props.product.variations.length === 0,
);

watchEffect(async () => {
  await fetchAvailabilities();
});

function isPDF(datasheetUrl: string) {
  return datasheetUrl.toLowerCase().endsWith(".pdf");
}

const getFileName = (url: string): string => {
  // Extract the file name from the URL
  const urlParts = url.split("/");
  return urlParts[urlParts.length - 1];
};

// Method to handle link click
const handleLinkClick = (datasheetUrl: string) => {
  // If the file is a PDF, let it open in a new tab
  if (isPDF(datasheetUrl)) {
    window.open(datasheetUrl, "_blank");
  } else {
    // Otherwise, trigger download by creating an anchor element programmatically
    const link = document.createElement("a");
    link.href = datasheetUrl;
    link.download = getFileName(datasheetUrl);
    link.click();
  }
};

async function fetchAvailabilities() {
  const product = props.product;
  const productRequests = [product, ...product.variations].map((variation) => {
    const clientCurrency = currentCurrency.value.code;
    const localWarehouse = variation.availabilityData.inventories?.find((i) =>
      i.fulfillmentCenterName?.toLocaleLowerCase().includes(clientCurrency.toLocaleLowerCase()),
    );
    const localStock = localWarehouse ? localWarehouse.inStockQuantity : 0;
    const heavyItem = (variation as VariationTypeExtension).ukHeavyTL || false;
    const lightweightTables = false; // Assuming lightweightTables is always false for now
    const productQuantity = 1;

    const globalWarehouses = variation.availabilityData.inventories.filter(
      (i) => !i.fulfillmentCenterName.toLocaleLowerCase().includes(currentCurrency.value.code.toLocaleLowerCase()),
    );

    const globallyAvailableStock = localWarehouse ? localWarehouse.backorderQuantity : 0;

    const usWarehouse = globalWarehouses.find((w) => w.fulfillmentCenterName.toLocaleLowerCase().includes("usd"));
    const isBrl = usWarehouse && currentCurrency.value.code === "BRL" && productQuantity > localStock;

    const warehouse = isBrl ? "USD" : "";
    const regionalStock = isBrl ? usWarehouse.inStockQuantity : 0;

    return {
      clientCurrency,
      warehouse,
      heavyItem,
      lightweightTables,
      productQuantity,
      globallyAvailableStock,
      localStock,
      regionalStock,
      productId: variation.code,
    };
  });

  // Call getItemsAvailability with all requests
  const availabilities = await getMultipleItemsAvailability(productRequests);
  availabilityData.value = availabilities;

  // Handle variations with no availability - for serialized items
  availabilities.forEach((availability) => {
    if (availability.availabilityResponse?.availability.toLocaleLowerCase() === "none") {
      const variation = props.product.variations.find((v) => v.code === availability.productId);
      if (variation) {
        // Helper function to check if warehouse has stock
        const hasWarehouseStock = (warehouseName: string) =>
          variation.availabilityData.inventories?.find(
            (i) =>
              i.fulfillmentCenterName?.toLocaleLowerCase().includes(warehouseName.toLocaleLowerCase()) &&
              i.inStockQuantity > 0,
          ) !== undefined;

        // Special availability rules for serialized items described under Board Item: Availability of serialized items
        let shouldUpdate = false;
        let updatedDuration = "2 Weeks";
        switch (currentCurrency.value.code) {
          case "USD":
            shouldUpdate = hasWarehouseStock("MUC") || hasWarehouseStock("JPN");
            break;
          case "EUR":
            shouldUpdate = hasWarehouseStock("THO") || hasWarehouseStock("JPN");
            break;
          case "GBP":
          case "SEK":
            shouldUpdate = hasWarehouseStock("THO") || hasWarehouseStock("JPN");
            if (hasWarehouseStock("MUC")) {
              shouldUpdate = true;
              updatedDuration = "Today";
            }
            break;
          case "CNY":
            shouldUpdate = hasWarehouseStock("THO") || hasWarehouseStock("MUC") || hasWarehouseStock("JPN");
            if (hasWarehouseStock("PRC")) {
              shouldUpdate = true;
              updatedDuration = "Today";
            }
            break;
          case "JPY":
            shouldUpdate = hasWarehouseStock("THO") || hasWarehouseStock("MUC");
            break;
          case "BRL":
            shouldUpdate = hasWarehouseStock("THO");
            updatedDuration = shouldUpdate ? "2-3 Weeks" : updatedDuration;
            shouldUpdate = hasWarehouseStock("MUC") || hasWarehouseStock("JPN");
            updatedDuration = shouldUpdate ? "6 Weeks" : updatedDuration;
            break;
        }

        // If we found stock in a Global warehouse, update the availability to "7-10 Days"
        if (shouldUpdate) {
          const index = availabilityData.value.findIndex((a) => a.productId === variation.code);
          if (
            index !== -1 &&
            availabilityData.value[index] &&
            availabilityData.value[index].availabilityResponse !== undefined
          ) {
            // @ts-expect-error: TypeScript incorrectly thinks availabilityData.value[index] might be undefined
            availabilityData.value[index].availabilityResponse.availability = updatedDuration;
            // @ts-expect-error: TypeScript incorrectly thinks availabilityData.value[index] might be undefined
            availabilityData.value[index].availabilityResponse.translationCode = updatedDuration;
          }
        }
      }
    }
  });
}

function getVariationDescription(variationDescription: string | undefined, variationCode: string | undefined) {
  if (!variationDescription || !variationCode) {
    return "";
  }

  if (variationDescription === variationCode) {
    return "";
  }

  return variationDescription;
}
</script>

<style lang="scss" scoped>
.product-variations {
  &__icon {
    :deep(svg) {
      --vc-icon-color: var(--color-primary-a1);
    }
  }
}
</style>
