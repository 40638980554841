import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { useI18n } from "vue-i18n";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { getIndexName } from "@/shared/thorlabs/search";
import { searchClient } from "./searchClient";
import type { IAlgoliaFamilyPage } from "../searchTypes";

export function getFamilyPagesPlugin() {
  const { t } = useI18n();

  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const familyPagesIndexName = getIndexName(environment, "familypages", savedLocale.value);

  return {
    getSources({ query }: { query: string }) {
      if (!query) {
        return [];
      }
      return [
        {
          sourceId: "familyPages",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: familyPagesIndexName,
                  params: {
                    query,
                    hitsPerPage: 3,
                    attributesToSnippet: ["name:10"],
                    snippetEllipsisText: "…",
                  },
                },
              ],
            });
          },
          templates: {
            header({
              html,
              state,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              state: { query: string };
            }) {
              return html`<div class="search-bar__header">
                <h6>${t("autocomplete.search.top_3_family_matches")}</h6>
                <a href="/search?q=${state.query}" class="hidden lg:flex">${t("autocomplete.search.view_results")}</a>
              </div>`;
            },
            item({
              item,
              html,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              item: IAlgoliaFamilyPage;
            }) {
              return html`<a href="${item.url}" class="family-match--item">
                <div class="family-match--image">
                  <img src="${item.imageUrl}" width="184" height="112" alt="" role="presentation" />
                </div>
                <div class="family-match--content-wrapper">
                  <div class="family-match--content">
                    <div class="family-match--name">${item.name}</div>
                    ${item.visNavName
                      ? html`<div class="family-match--info">
                          <span class="inline-flex size-3 lg:hidden whitespace-nowrap">-></span>
                          in ${item.visNavName}
                        </div>`
                      : ""}
                  </div>
                  <div class="family-match--link">${t("autocomplete.search.view_product_family")}</div>
                </div>
              </a>`;
            },
            noResults() {
              return t("autocomplete.search.empty_family_pages");
            },
          },
        },
      ];
    },
  };
}
