import mitt from "mitt";
import type { Product, VariationTypeExtension } from "@/core/api/graphql/types";
import type { Emitter } from "mitt";
import type { Ref } from "vue";

export type EventType = {
  tabMounted: { tabId: number };
  tabUpdated: { tabId: number };
  tabChanged: void;
  showSupportDocumentsDialog: { itemId: string };
  showQuickviewLinkDialog: { itemId: string; isDraggable?: boolean | undefined };
  changeQuickviewItemIds: { itemIds: string[] };
  showSupportDocumentsDialog2: { product: Product | VariationTypeExtension };
  showPriceRequestDialog: { product: Product | VariationTypeExtension };
  showLeadTimeDialog: { product: Product | VariationTypeExtension };
  showVolumePricingDialog: { product: Product | VariationTypeExtension };
  familyPageTabMounted: { container: Ref<HTMLElement> | undefined };
  familyPageTabUpdated: { container: Ref<HTMLElement> | undefined };
  familyPageTabChanged: { container: Ref<HTMLElement> | undefined };
  familyPageSubgroupMounted: { container: Ref<HTMLElement> | undefined };
  familyPageSubgroupUpdated: { container: Ref<HTMLElement> | undefined };
  familyPageSubgroupChanged: { container: Ref<HTMLElement> | undefined };
};

export const eventBus: Emitter<EventType> = mitt<EventType>();
