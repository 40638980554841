<template>
  <div class="action-buttons my-8 flex flex-wrap items-center justify-center gap-4 whitespace-nowrap md:mb-0">
    <VcButton v-if="cadPdf" color="secondary" variant="outlineTL" size="sm" @click="openDocument(cadPdf)">
      <VcIcon name="icon-cad" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">CAD PDF</span>
    </VcButton>
    <VcButton v-if="eDrawing" color="secondary" variant="outlineTL" size="sm" @click="openDocument(eDrawing)">
      <VcIcon name="icon-box" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">3D</span>
    </VcButton>
    <VcButton
      v-if="familyPageUrl"
      color="secondary"
      variant="outlineTL"
      size="sm"
      @click="goToUrl(familyPageUrl || '')"
    >
      <VcIcon name="icon-arrow-up" :size="16" class="self-center text-[--color-neutral-a05]" />
      <span class="product-image-buttons__text">Product Family</span>
    </VcButton>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { normalizeDownloadFileName, openFile } from "@/shared/thorlabs/support-documents/types";
import type { Asset } from "@/core/api/graphql/types";

interface IProps {
  cadPdf: Asset | undefined;
  eDrawing: Asset | undefined;
  productCode: string;
  familyPageUrl?: string;
}

const props = defineProps<IProps>();

const openDocument = async (document: Asset) => {
  const fileName = normalizeDownloadFileName(
    document.name ?? "",
    document.group ?? "",
    document.description ?? "",
    props.productCode,
  );

  await openFile(document.id, fileName);
};

const goToUrl = (url: string) => {
  window.open(url, "_blank");
};
</script>

<style lang="scss" scoped>
.action-buttons {
  @media (max-width: 374px) {
    @apply gap-3;
    .vc-button--outlineTL--secondary {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }
  .vc-button--outlineTL--secondary {
    @apply normal-case;
  }
}
</style>
