import { computed, ref, watch } from "vue";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { FormCache, FormLoader } from "@/shared/forms/forms-sdk";
import { useSlugInfo } from "./useSlugInfo";
import type { ExtendedFormContainer } from "@/shared/forms/types";
import type { IFormContent } from "@/shared/thorlabs/forms/types";

const formCache = new FormCache();

export function useForm(name: string) {
  const language = useLanguages().currentLocale.value;
  let cachedForm = formCache.get(`${name}-${language}`) as string;
  const formContainer = ref<ExtendedFormContainer | undefined>(undefined);

  if (cachedForm) {
    formContainer.value = JSON.parse(cachedForm) as ExtendedFormContainer;
  } else {
    const { slugInfo, hasContent, contentResult, fetchContent } = useSlugInfo(name);

    watch(slugInfo, async () => {
      if (!formContainer.value && hasContent.value) {
        await fetchContent();
      }
    });

    const formContent = computed<IFormContent>(() => {
      return JSON.parse(contentResult?.value?.page?.content ?? "{}") as IFormContent;
    });

    watch(formContent, async () => {
      if (!formContainer.value) {
        formContainer.value = await loadForm(formContent.value);
      }
    });
  }

  async function loadForm(formContent: IFormContent | undefined): Promise<ExtendedFormContainer | undefined> {
    if (formContent) {
      const formLoader = new FormLoader({ baseURL: globals.optimizelyHost + "/" });
      const id = formContent.contentLink.guidValue.replace(/-/g, "");

      let form = {};
      try {
        form = await formLoader.getForm(id, language);
      } catch (error) {
        cachedForm = formCache.get(`${name}-en`) as string;
        form = (cachedForm ? JSON.parse(cachedForm) : await formLoader.getForm(id, "en")) as ExtendedFormContainer;
      }
      formCache.set(`${name}-${language}`, form);
      return form as ExtendedFormContainer;
    }
    return undefined;
  }

  return {
    formContainer,
  };
}
