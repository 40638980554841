import { createGlobalState } from "@vueuse/core";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { globals } from "@/core/globals";

function _useEditMode() {
  const route = useRoute();
  const { optimizelyHost } = globals;

  const isEditable = computed(() => {
    return route?.fullPath.indexOf("/CMS/Content/") >= 0 && route.query["epieditmode"] == "true";
  });

  const isPreview = computed(() => {
    return route.fullPath.indexOf("/CMS/Content/") >= 0 && route.query["epieditmode"];
  });

  const url = computed(() => {
    return route.fullPath;
  });

  const injectCommunicationScript = (): void => {
    const communicationScript = document.createElement("script");
    communicationScript.src = `${optimizelyHost}/ui/cms/latest/clientresources/communicationinjector.js`;
    document.body.appendChild(communicationScript);
  };

  return {
    injectCommunicationScript,
    url,
    isEditable,
    isPreview,
  };
}

export const useEditMode = createGlobalState(_useEditMode);
