import { createGlobalState } from "@vueuse/core";
import { computed, readonly, ref, shallowRef } from "vue";
import { searchProducts } from "@/core/api/graphql/catalog";
import { Logger } from "@/core/utilities";
import type { ProductsSearchParams } from "../types";
import type { Product } from "@/core/api/graphql/types";

function _useAutocompleteProducts() {
  const loading = ref(true);
  const products = shallowRef<Product[]>([]);
  let currentAbortController: AbortController | null = null;

  const productsById = computed(() =>
    products.value.reduce(
      (result, product, index) => {
        result[product.id] = { index, product };
        return result;
      },
      {} as Record<string, { index: number; product: Product }>,
    ),
  );

  async function fetchProducts(searchParams: Partial<ProductsSearchParams>, lightVersionData?: boolean) {
    if (currentAbortController) {
      currentAbortController.abort("Newer search request started");
    }

    currentAbortController = new AbortController();
    loading.value = true;
    products.value = [];
    const lightVersion = lightVersionData ?? false;
    try {
      const { items = [] } = await searchProducts(searchParams, {
        withFacets: true,
        withImages: true,
        withZeroPrice: true,
        lightVersion,
        signal: currentAbortController.signal,
      });

      products.value = items;
    } catch (e) {
      Logger.error(`useAutocompleteProducts.${fetchProducts.name}`, e);
      throw e;
    } finally {
      loading.value = false;
    }
  }

  return {
    productsById,
    fetchProducts,
    loading: readonly(loading),
    products: computed(() => /** @see: https://github.com/vuejs/core/issues/8036 */ products.value.slice()),
  };
}

export const useAutocompleteProducts = createGlobalState(_useAutocompleteProducts);
