<template>
  <nav class="vc-breadcrumbs">
    <ol ref="list" class="vc-breadcrumbs__list items-center leading-none" @scroll="checkScroll">
      <li v-if="!hideHomeIcon" class="flex print:flex">
        <a :href="homeIconUrl" class="vc-breadcrumbs__link" :aria-label="$t('common.buttons.home')">
          <VcIcon name="home-alt" :size="16" class="vc-breadcrumbs__icon flex" />
        </a>
      </li>
      <template v-for="(item, i) in filteredItems" :key="i">
        <template v-if="i < filteredItems.length - 1">
          <li v-if="!hideHomeIcon || i > 0" class="vc-breadcrumbs__item">
            <span class="vc-breadcrumbs__slash">/</span>
          </li>
          <li class="vc-breadcrumbs__item">
            <component
              :is="item.route ? 'a' : 'span'"
              v-dompurify-html="item.title"
              :href="item.route"
              class="vc-breadcrumbs__link"
            ></component>
          </li>
        </template>
        <template v-else>
          <li class="vc-breadcrumbs__item">
            <span class="vc-breadcrumbs__slash">/</span>
          </li>
          <li v-dompurify-html="item.title" class="vc-breadcrumbs__item"></li>
        </template>
        <!-- Last breadcrumbs item -->
      </template>
    </ol>
  </nav>
</template>

<script setup lang="ts">
import { defineProps, ref, onMounted, onUpdated, onUnmounted, computed } from "vue";
interface IProps {
  items: IBreadcrumb[];
  hideHomeIcon?: boolean;
  isVisNavHomeIcon?: boolean;
}

const props = defineProps<IProps>();

const homeIconUrl = computed(() => {
  if (props.isVisNavHomeIcon && props.items?.length > 0) {
    return typeof props.items[0].route === "string" ? props.items[0].route : "/";
  }
  return "/";
});

const list = ref<HTMLElement | null>(null);

const filteredItems = computed(() => {
  if (props.isVisNavHomeIcon && props.items?.length > 0) {
    return props.items.slice(1);
  }
  return props.items;
});

const checkScroll = () => {
  if (list.value) {
    if (list.value.scrollWidth - list.value.scrollLeft === list.value.clientWidth) {
      list.value.classList.add("scrolled-to-end");
    } else {
      list.value.classList.remove("scrolled-to-end");
    }

    if (list.value.scrollLeft === 0) {
      list.value.classList.add("scrolled-from-start");
    } else {
      list.value.classList.remove("scrolled-from-start");
    }
  }
};

onMounted(() => {
  if (list.value) {
    list.value.classList.add("scrolled-from-start");
    list.value.addEventListener("scroll", checkScroll);
  }
});

onUpdated(checkScroll);

onUnmounted(() => {
  if (list.value) {
    (list.value as HTMLElement).removeEventListener("scroll", checkScroll);
  }
});
</script>

<style lang="scss">
.vc-breadcrumbs {
  @apply w-full relative;

  &__list {
    @apply flex flex-wrap text-sm font-medium;

    @media (max-width: theme("screens.md")) {
      &.scrolled-to-end::after {
        display: none;
      }

      &.scrolled-from-start::before {
        display: none;
      }

      @apply flex flex-nowrap overflow-x-auto w-full;

      &::after {
        @apply absolute top-0 content-[''] right-[-3px] bottom-0 w-12 bg-gradient-to-r from-transparent to-white pointer-events-none;
      }

      &::before {
        @apply absolute top-0 content-[''] left-[-3px] bottom-0 w-12 bg-gradient-to-l from-transparent to-white pointer-events-none;
      }
    }
    li {
      @apply mb-[9px];
    }
  }

  &__item {
    @apply text-[--color-neutral-a1];

    @media (max-width: theme("screens.md")) {
      @apply whitespace-nowrap;
    }

    br {
      @apply hidden;
    }
  }

  &__link {
    @apply text-[--color-link];

    &:hover {
      @apply text-[--color-link-hover];
    }
  }

  &__slash {
    @apply mx-2 text-[20px];
  }

  @-moz-document url-prefix() {
    scrollbar-width: thin;
  }
}

@media print {
  .vc-breadcrumbs__list {
    @apply flex-wrap overflow-visible; // Ensure breadcrumbs wrap and are fully visible
  }

  .vc-breadcrumbs__item {
    @apply whitespace-normal; // Prevent items from being truncated
  }

  .vc-breadcrumbs__list::before,
  .vc-breadcrumbs__list::after {
    display: none; // Remove gradient effects for print
  }
}
</style>
