<template>
  <BannerAlertHeader
    v-if="selectedBanner && selectedBanner.position === 'Top'"
    :id="`bannerAlertHeader${selectedBanner.position}`"
    ref="bannerAlertHeaderRef"
    :block="selectedBanner"
    class="print:hidden"
  />
  <!-- Mobile header -->
  <MobileHeader
    v-if="isMobile"
    ref="mobileHeaderRef"
    :logo="headerBlock?.logo"
    :mobile-menu-items="headerBlock?.mobileMenuItems"
    :banner-block="selectedBanner"
    class="print:hidden"
    :class="pageRoute"
  />
  <!-- Desktop header -->
  <template v-else>
    <BottomHeader
      ref="bottomHeaderRef"
      aria-label="header"
      :class="[
        'header pageRoute border-b border-b-[--color-neutral-a4] transition-[top] print:hidden',
        { 'sticky z-50': isHeaderVisible && isScrolledPastHeader },
        isHeaderVisible ? 'slideDown top-0' : 'slideUp top-[-83px]',
      ]"
      :logo="headerBlock?.logo"
      :desktop-menu-items="headerBlock?.desktopMenuItems"
      role="banner"
    />
  </template>
  <BannerAlertHeader
    v-if="selectedBanner && selectedBanner.position === 'Bottom' && !isMobile"
    ref="bannerAlertHeaderRefBottom"
    :block="selectedBanner"
    class="print:hidden"
  />
</template>

<script setup lang="ts">
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { onMounted, onBeforeUnmount, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useCurrency } from "@/core/composables";
import { useSlugInfo } from "@/shared/common";
import BannerAlertHeader from "./_internal/banner-alert-header.vue";
import BottomHeader from "./_internal/bottom-header.vue";
import MobileHeader from "./_internal/mobile-header.vue";
import type { IBannerBlock, IHeaderBlock } from "@/shared/thorlabs/blocks";

const route = useRoute();

const pageRoute = computed(() => {
  return route.path === "/cart" ? "cartPage" : "";
});
const breakpoints = useBreakpoints(breakpointsTailwind);

// For optimization on mobile devices
const isMobile = breakpoints.smaller("lg");
const isHeaderVisible = ref(true);

const { slugInfo, hasContent, contentResult, fetchContent } = useSlugInfo("Header");

watch(slugInfo, async () => {
  if (hasContent.value) {
    await fetchContent();
  }
});

const { currentCurrency } = useCurrency();
let selectedBanner: IBannerBlock;

const headerBlock = computed<IHeaderBlock | undefined>(() => {
  try {
    const headerBlockData: IHeaderBlock = JSON.parse(contentResult?.value?.page?.content ?? "{}") as IHeaderBlock;
    if (headerBlockData) {
      if (headerBlockData.banner && headerBlockData.banner.length > 0) {
        headerBlockData.banner.sort((a, b) => {
          const aDate = new Date((a.contentLink.expanded as IBannerBlock).saved);
          const bDate = new Date((b.contentLink.expanded as IBannerBlock).saved);
          return bDate.getTime() - aDate.getTime();
        });

        selectedBanner = headerBlockData.banner.find(
          (banner) =>
            (banner.contentLink.expanded as IBannerBlock).currency.toLowerCase() ===
            currentCurrency.value?.code.toLowerCase(),
        )?.contentLink.expanded as IBannerBlock;
      }

      return headerBlockData as IHeaderBlock;
    }
  } catch (error) {
    console.error("Failed to parse headerBlock data:", error);
  }

  return undefined;
});

const isScrollingUp = ref(false);
let lastScrollPosition = 0;
const isScrolledPastHeader = ref(false);

onMounted(() => {
  const handleScroll = () => {
    const currentScrollPosition = window.scrollY || document.documentElement.scrollTop;
    const headerElement = document.querySelector('[aria-label="header"]');
    const headerHeight = (headerElement as HTMLElement)?.offsetHeight || 0;
    isScrollingUp.value = currentScrollPosition < lastScrollPosition;
    isScrolledPastHeader.value = currentScrollPosition > headerHeight;

    isHeaderVisible.value = isScrollingUp.value || !isScrolledPastHeader.value;

    lastScrollPosition = currentScrollPosition;
  };

  window.addEventListener("scroll", handleScroll, false);

  onBeforeUnmount(() => {
    window.removeEventListener("scroll", handleScroll, false);
  });
});

watch(isMobile, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    const aaPanel = document.querySelector(".aa-Panel");
    if (aaPanel) {
      aaPanel.remove();
    }
  }
});

defineExpose({ isHeaderVisible });
</script>

<style lang="scss" scoped>
.header {
  transition: top 0.3s ease-in-out; // keep same animation as the tabNavBar
  top: -83px;
}

.slideDown {
  top: 0;
}

.slideUp {
  top: -83px;
}
</style>
