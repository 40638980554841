import { computed, readonly, ref, shallowRef } from "vue";
import { getCountriesWithoutEmbargoed } from "@/core/api/graphql/common";
import { Logger } from "../utilities";
import type { CountryType } from "@/core/api/graphql/types";

const loading = ref(false);
const countriesWithoutEmbargoed = shallowRef<CountryType[]>([]);

async function loadCountriesWithoutEmbargoed() {
  loading.value = true;

  try {
    countriesWithoutEmbargoed.value = await getCountriesWithoutEmbargoed();
  } catch (e) {
    Logger.error(loadCountriesWithoutEmbargoed.name, e);
  } finally {
    loading.value = false;
  }
}

export function useCountriesWithoutEmbargoed() {
  return {
    loadCountriesWithoutEmbargoed,
    loading: readonly(loading),
    countriesWithoutEmbargoed: computed(() => countriesWithoutEmbargoed.value),
  };
}
