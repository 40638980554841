import { getMultipleItemsAvailability } from "@/core/api/graphql/account/queries/getMultipleItemsAvailability";
import { useCurrency } from "@/core/composables";
import { ValidationErrorObjectType } from "@/core/enums";
import type {
  ShortCartFragment,
  LineItemTypeExtension,
  OrderLineItemType,
  ValidationErrorType,
  ProductAvailabilityRequest,
  MoneyType,
} from "@/core/api/graphql/types";
import type { ICurrency } from "@/core/types";
import type { ItemForAddBulkItemsToCartResultsModalType } from "@/shared/cart";

const { currentCurrency } = useCurrency();

/** @deprecated No longer used. Add to cart mutations now return ID instead. */
export function getLineItemValidationErrorsGroupedBySKU(
  errors: ValidationErrorType[] = [],
): Record<string, ValidationErrorType[]> {
  const result: Record<string, ValidationErrorType[]> = {};

  errors?.forEach((error) => {
    const sku = error.objectId!;

    result[sku] = result[sku] || [];
    result[sku].push(error);
  });

  return result;
}

export function getItemsForAddBulkItemsToCartResultsModal(
  inputItems: OrderLineItemType[] | LineItemTypeExtension[],
  cart: ShortCartFragment,
): ItemForAddBulkItemsToCartResultsModalType[] {
  return inputItems.map<ItemForAddBulkItemsToCartResultsModalType>((item) => ({
    productExists: !!item.product,
    productId: item.productId,
    name: item.name,
    sku: item.sku,
    quantity: item.quantity,
    slug: item.product?.slug,
    isAddedToCart: !cart.validationErrors.some(
      (error) => error.objectType == ValidationErrorObjectType.CatalogProduct && error.objectId === item.productId,
    ),
  }));
}

export async function getAvailability(items: LineItemTypeExtension[]) {
  const availabilityMap = items.map((item) => {
    const clientCurrency = currentCurrency.value.code;
    const localWarehouse = item.product?.availabilityData.inventories.find((i) =>
      i.fulfillmentCenterName.toLocaleLowerCase().includes(currentCurrency.value.code.toLocaleLowerCase()),
    );
    const localStock = localWarehouse ? localWarehouse.inStockQuantity : 0;
    const heavyItem = item.product?.ukHvyTL ? item.product?.ukHvyTL : false;
    const lightweightTables = false;
    const productQuantity = item.quantity;
    const globalWarehouses = item.product?.availabilityData.inventories.filter(
      (i) => !i.fulfillmentCenterName.toLocaleLowerCase().includes(currentCurrency.value.code.toLocaleLowerCase()),
    );
    const globallyAvailableStock = localWarehouse ? localWarehouse.backorderQuantity : 0;

    const usWarehouse = globalWarehouses?.find((w) => w.fulfillmentCenterName.toLocaleLowerCase().includes("usd"));
    const isBrl = usWarehouse && currentCurrency.value.code === "BRL" && productQuantity > localStock;

    const warehouse = isBrl ? "USD" : "";
    const regionalStock = isBrl ? usWarehouse.inStockQuantity : 0;

    return <ProductAvailabilityRequest>{
      clientCurrency,
      warehouse,
      heavyItem,
      productQuantity,
      globallyAvailableStock,
      localStock,
      lightweightTables,
      regionalStock,
      productId: item.id,
    };
  });

  if (availabilityMap === null || availabilityMap === undefined) {
    return null;
  }

  return await getMultipleItemsAvailability(availabilityMap);
}

export function formatPriceForCurrency(value: MoneyType | undefined, currency: ICurrency): string {
  if (!value) {
    return "N/A";
  }

  const amount = value.amount;

  switch (currency.code) {
    case "USD":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "EUR":
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "GBP":
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "SEK": {
      const formattedSEK = new Intl.NumberFormat("sv-SE", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
        .format(amount)
        .replace(/\s/g, " ");

      return `kr ${formattedSEK}`;
    }

    case "CNY":
      return new Intl.NumberFormat("zh-CN", {
        style: "currency",
        currency: "CNY",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    case "JPY":
      return new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        minimumFractionDigits: 0, // No cents for JPY
        maximumFractionDigits: 0,
      }).format(amount);

    case "BRL":
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);

    default:
      return amount.toString(); // Fallback if the currency isn't listed
  }
}

export function getProductFamillyPageUrl(item: LineItemTypeExtension) {
  let url = item.product?.mainProductId
    ? item.product?.masterVariation?.descriptionsWithFallbacksTL?.find((x) => x.reviewType === "FamilyPageUrl")?.content
    : item.product?.descriptionsWithFallbacksTL?.find((x) => x.reviewType === "FamilyPageUrl")?.content;

  if (url) {
    url += `?pn=${item.product?.mainProductId ? item.product?.masterVariation?.code : item.product?.code}`;
  }

  return url;
}
