import { globals } from "@/core/globals";
import { graphqlClient } from "../../../client";
import mutationDocument from "./changeCartCurrencyMutation.graphql";
import type { CartExtension, Mutations, MutationsChangeCartCurrencyArgs } from "@/core/api/graphql/types";

export async function changeCartCurrency(
  cartId: string,
  newCurrencyCode: string,
  cartName?: string,
  oldCurrencyCode?: string,
): Promise<CartExtension> {
  const { storeId, userId, currencyCode, cultureName } = globals;

  const { data } = await graphqlClient.mutate<
    Required<Pick<Mutations, "changeCartCurrency">>,
    MutationsChangeCartCurrencyArgs
  >({
    mutation: mutationDocument,
    variables: {
      command: {
        cartId,
        userId,
        storeId,
        currencyCode: oldCurrencyCode ?? currencyCode,
        cultureName,
        newCurrencyCode,
        cartName,
      },
    },
  });

  return data!.changeCartCurrency as CartExtension;
}
