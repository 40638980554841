import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { useI18n } from "vue-i18n";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { getIndexName } from "@/shared/thorlabs/search";
import { debouncedFunction } from "./debounce";
import { searchClient } from "./searchClient";
import type { IAlgoliaProduct } from "../searchTypes";

export function getProductsPlugin() {
  const { t } = useI18n();

  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const productsIndexName = getIndexName(environment, "products", savedLocale.value);

  return {
    getSources({ query }: { query: string }) {
      if (!query) {
        return [];
      }
      return debouncedFunction([
        {
          sourceId: "products",
          getItems() {
            return getAlgoliaResults<IAlgoliaProduct>({
              searchClient,
              queries: [
                {
                  indexName: productsIndexName,
                  params: {
                    query,
                    hitsPerPage: 3,
                    attributesToSnippet: ["name:10"],
                    snippetEllipsisText: "…",
                  },
                },
              ],
              transformResponse({ hits }) {
                const lowerQuery = query.toLowerCase().trim();
                const exactMatch = hits[0]?.find((hit) => hit.objectID.toLowerCase() == lowerQuery);

                if (exactMatch) {
                  exactMatch.exactMatch = true;
                  if (exactMatch.itemStatus == "Active") {
                    //filter out other items that are not exact match or starts with the query
                    //sort them so the exact match is first
                    return hits[0]
                      .filter((item) => {
                        const lowerCode = item.objectID.toLowerCase();
                        return lowerCode === lowerQuery || lowerCode.startsWith(lowerQuery);
                      })
                      .sort((a, b) => {
                        if (a.objectID.toLowerCase() === lowerQuery && b.objectID.toLowerCase() !== lowerQuery) {
                          return -1;
                        }
                        if (b.objectID.toLowerCase() === lowerQuery && a.objectID.toLowerCase() !== lowerQuery) {
                          return 1;
                        }
                        return 0;
                      });
                  }
                  if (exactMatch.itemStatus == "Superseded" || exactMatch.itemStatus == "Discontinued") {
                    exactMatch.hideOtherSources = true;
                    return [exactMatch];
                  }
                }

                return hits;
              },
            });
          },
          templates: {
            header({ html }: { html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string }) {
              return html`<h6>${t("autocomplete.search.top_3_item_matches")}</h6>`;
            },
            item({
              item,
              html,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              item: IAlgoliaProduct;
            }) {
              const itemClass =
                item.itemStatus === "Discontinued"
                  ? "discontinued"
                  : item.itemStatus === "Superseded"
                    ? "discontinued superseded"
                    : "";
              const lowerQuery = query.toLowerCase().trim();

              if (item.objectID.toLowerCase() == lowerQuery) {
                if (item.itemStatus == "Active") {
                  return html`
                    <a href="${item.familyPageUrl}?pn=${item.objectID}" class="product-match--item ${itemClass}">
                      <div class="product-match--image">
                        <img src="${item.imageUrl}" width="80" height="80" alt="" role="presentation" />
                      </div>
                      <div class="product-match--content">
                        <div class="product-match--name">
                          ${item.objectID}
                          <div class="exact-match">
                            ${t("autocomplete.search.exact_match")}<span
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M15.1499 3.35039C14.9249 3.12539 14.5749 3.12539 14.3499 3.35039L5.84994 11.6004L1.64994 7.47539C1.42494 7.25039 1.07494 7.27539 0.849942 7.47539C0.624942 7.70039 0.649942 8.05039 0.849942 8.27539L5.27494 12.5754C5.42494 12.7254 5.62494 12.8004 5.84994 12.8004C6.07494 12.8004 6.24994 12.7254 6.42494 12.5754L15.1499 4.10039C15.3749 3.92539 15.3749 3.57539 15.1499 3.35039Z"
                                  fill="#1A8245"
                                /></svg
                            ></span>
                          </div>
                        </div>
                        <div class="product-match--info">${item.name}</div>
                        ${item.softwareDownloadPageUrl
                          ? html` <a href="${item.softwareDownloadPageUrl}" class="product-match--link"
                              >${t("autocomplete.search.software_download_page")}
                              <span>
                                <svg class="vc-icon shrink-0">
                                  <use
                                    href="/static/thorlabs/icons/basic/icon-arrow-up-right.svg?#icon"
                                  ></use></svg></span
                            ></a>`
                          : ``}
                      </div>
                    </a>
                  `;
                } else if (item.itemStatus == "Superseded") {
                  return html` <div class="discontinued-group ${itemClass}">
                    <div class="discontinued-item--status">
                      ${item.objectID} ${t("autocomplete.search.discontinued_item_replaced_by")}
                      <a href="${item.replacementItemFamilyPageUrl}" target="_blank">${item.replacementItemId}</a>
                    </div>
                    <div class="discontinued-group--wrapper">
                      <a href="${item.url}" class="discontinued-item">
                        <div class="discontinued-item--wrapper">
                          <div class="discontinued-item--info">
                            <span class="discontinued-item--name">${item.objectID}</span>
                            <span class="discontinued-item--badge">${t("autocomplete.search.discontinued")}</span>
                          </div>
                          <div class="discontinued-item--details">${item.name}</div>
                        </div>
                        <div class="discontinued-item--image">
                          <img src="${item.imageUrl}" width="268" height="128" alt="" role="presentation" />
                        </div>
                      </a>

                      <a
                        href="${item.replacementItemFamilyPageUrl}"
                        class="discontinued-item discontinued-item--replacement"
                      >
                        <div class="discontinued-item--wrapper">
                          <div class="discontinued-item--name">${item.replacementItemId}</div>
                          <div class="discontinued-item--details">${item.replacementItemName}</div>
                        </div>
                        <div class="discontinued-item--image">
                          <img
                            src="${item.replacementItemImageUrl}"
                            width="268"
                            height="128"
                            alt=""
                            role="presentation"
                          />
                        </div>
                      </a>
                    </div>
                  </div>`;
                } else if (item.itemStatus == "Discontinued") {
                  return html` <div class="discontinued-group ${itemClass}">
                    <div class="discontinued-item--status">
                      ${item.objectID} ${t("autocomplete.search.has_been_discontinued")}
                    </div>
                    <a href="${item.url}" class="discontinued-item">
                      <div class="discontinued-item--wrapper">
                        <div class="discontinued-item--info">
                          <span class="discontinued-item--name">${item.objectID}</span>
                          <span class="discontinued-item--badge">${t("autocomplete.search.discontinued")}</span>
                        </div>
                        <div class="discontinued-item--details">${item.name}</div>
                      </div>
                      <div class="discontinued-item--image">
                        <img src="${item.imageUrl}" width="268" height="128" alt="" role="presentation" />
                      </div>
                    </a>
                  </div>`;
                }
              }
              const itemUrl =
                item.itemStatus !== "Discontinued" ? `${item.familyPageUrl}?pn=${item.objectID}` : item.url;
              return html`<a href="${itemUrl}" class="product-match--item ${itemClass}">
                <div class="product-match--image">
                  <img src="${item.imageUrl}" width="80" height="80" alt="" role="presentation" />
                </div>
                <div class="product-match--content">
                  <div class="flex flex-wrap gap-1 items-center">
                    <div class="product-match--name">${item.objectID}</div>
                    ${item.itemStatus == "Discontinued"
                      ? html`<span class="discontinued-item--badge">${t("autocomplete.search.discontinued")}</span>`
                      : ""}
                  </div>
                  <div class="product-match--info">${item.name}</div>
                </div>
              </a>`;
            },
            noResults() {
              return t("autocomplete.search.empty_products");
            },
          },
        },
      ]);
    },
  };
}
