import { graphqlClient } from "../../../client";
import getCountriesWithoutEmbargoedQuery from "./getCountriesWithoutEmbargoed.graphql";
import type { CountryType, Query } from "@/core/api/graphql/types";

export async function getCountriesWithoutEmbargoed(): Promise<CountryType[]> {
  const { data } = await graphqlClient.query<Required<Pick<Query, "getCountriesWithoutEmbargoed">>>({
    query: getCountriesWithoutEmbargoedQuery,
  });

  return data.getCountriesWithoutEmbargoed;
}
