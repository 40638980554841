import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { useI18n } from "vue-i18n";
import { useLanguages } from "@/core/composables";
import { globals } from "@/core/globals";
import { getIndexName } from "@/shared/thorlabs/search";
import { searchClient } from "./searchClient";
import type { IAlgoliaSoftwarePage } from "../searchTypes";

export function getSoftwarePagesPlugin() {
  const { t } = useI18n();

  const { environment } = globals;
  const { savedLocale } = useLanguages();
  const softwareDownloadsIndexName = getIndexName(environment, "softwarepages", savedLocale.value);

  return {
    getSources({ query }: { query: string }) {
      if (!query) {
        return [];
      }
      return [
        {
          sourceId: "softwarePages",
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: softwareDownloadsIndexName,
                  params: {
                    query,
                    hitsPerPage: 3,
                    attributesToSnippet: ["name:10"],
                    snippetEllipsisText: "…",
                  },
                },
              ],
            });
          },
          templates: {
            // TODO: Bogdan change static text with resource pages.search.software_downloads
            header({ html }: { html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string }) {
              return html`<h6>
                <svg class="vc-icon shrink-0">
                  <use href="/static/thorlabs/icons/basic/download.svg?#icon"></use>
                </svg>
                ${t("autocomplete.search.software_downloads")}
              </h6>`;
            },
            item({
              item,
              html,
            }: {
              html: (literals: TemplateStringsArray, ...placeholders: unknown[]) => string;
              item: IAlgoliaSoftwarePage;
            }) {
              return html` <span class="aa-ItemContent aa-ItemContent--link">
                <a href=${item.url}>${item.name}</a>
              </span>`;
            },
            noResults() {
              return t("autocomplete.search.software_downloads");
            },
          },
        },
      ];
    },
  };
}
