<template>
  <component
    :is="componentTag"
    v-bind="attrs"
    :target="target"
    :type="type"
    :disabled="!enabled"
    :title="title"
    :class="[
      'vc-button',
      `vc-button--size--${size}`,
      `vc-button--color--${color}`,
      `vc-button--${variant}--${color}`,
      {
        'vc-button--icon': !!icon,
        'vc-button--disabled': !enabled,
        'vc-button--loading': loading,
        'vc-button--truncate': truncate,
        'vc-button--full-width': fullWidth,
        'vc-button--no-wrap': noWrap,
        'vc-button--link': variant === 'link',
        'vc-button--custom': color === 'custom',
      },
    ]"
    :style="{
      '--custom-color': customColor,
      '--custom-hover-color': customHoverColor,
    }"
    @click="onClick"
  >
    <span class="vc-button__content">
      <VcIcon v-if="icon && typeof icon === 'string'" class="vc-button__icon" :name="icon" />

      <template v-else>
        <span v-if="$slots.prepend || prependIcon" class="vc-button__prepend">
          <slot name="prepend">
            <VcIcon v-if="prependIcon" class="vc-button__icon" :name="prependIcon" />
          </slot>
        </span>

        <span v-if="$slots.default" class="vc-button__slot">
          <template v-if="showCompleteText">
            <VcIcon v-if="!completeWithError" name="check-circle-alt" :size="20" class="mr-2" />
            <VcIcon v-if="completeWithError" name="close-narrow" :size="20" class="mr-2" />
            <span>{{ completeText }}</span>
          </template>
          <slot v-else />
        </span>

        <span v-if="$slots.append || appendIcon" class="vc-button__append">
          <slot name="append">
            <VcIcon v-if="appendIcon" class="vc-button__icon" :name="appendIcon" />
          </slot>
        </span>
      </template>
    </span>

    <span v-if="loading" class="vc-button__loader">
      <slot name="loader">
        <span class="vc-button__loader-icon size-6">
          <svg
            id="icon"
            class="circular-loader"
            viewBox="25 25 50 50"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="currentColor" stroke-width="4" />
          </svg>
        </span>
      </slot>
    </span>
  </component>
</template>

<script setup lang="ts">
import { eagerComputed } from "@vueuse/core";
import { computed, defineEmits, ref, nextTick } from "vue";
import type { RouteLocationRaw } from "vue-router";

interface IAttributes {
  to?: RouteLocationRaw | null;
  href?: string;
  style?: {
    minWidth?: string;
  };
}

interface IProps {
  color?: "primary" | "secondary" | "success" | "info" | "neutral" | "warning" | "danger" | "accent" | "custom";
  customColor?: string;
  customHoverColor?: string;
  size?: "xs" | "sm" | "md" | "lg" | "customTL" | "auto";
  variant?: "solid" | "outline" | "outlineTL" | "no-border" | "no-background" | "link";
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  to?: RouteLocationRaw | null;
  externalLink?: string;
  target?: "_self" | "_blank";
  prependIcon?: string;
  appendIcon?: string;
  icon?: boolean | string;
  title?: string;
  truncate?: boolean;
  fullWidth?: boolean;
  noWrap?: boolean;
  minWidth?: string;
  completeText?: string;
  completeWithError?: boolean;
  enableCompleteText?: boolean;
}

const emit = defineEmits<IEmits>();

const props = withDefaults(defineProps<IProps>(), {
  color: "primary",
  size: "md",
  variant: "solid",
  type: "button",
  disabled: false,
  to: null,
  truncate: false,
  fullWidth: false,
  noWrap: false,
  completeText: "Done",
  completeWithError: false,
  enableCompleteText: true,
});

const loading = ref(false);
const showCompleteText = ref(false);
const enabled = eagerComputed<boolean>(() => !props.disabled && !loading.value);
const isRouterLink = eagerComputed<boolean>(() => !!props.to && enabled.value);
const isExternalLink = eagerComputed<boolean>(() => !!props.externalLink && enabled.value);

const target = computed<string | undefined>(() =>
  props.target && (isExternalLink.value || isRouterLink.value) ? props.target : undefined,
);

const componentTag = computed(() => {
  if (isRouterLink.value) {
    return "router-link";
  }

  if (isExternalLink.value) {
    return "a";
  }

  return "button";
});

const attrs = computed(() => {
  const attributes: IAttributes = {};

  if (componentTag.value === "router-link") {
    attributes.to = props.to;
  }

  if (componentTag.value === "a") {
    attributes.href = props.externalLink;
  }

  if (props.minWidth) {
    attributes.style = {
      minWidth: props.minWidth,
    };
  }

  return attributes;
});

export interface IEmits {
  (event: "click", callback: (result: unknown) => void): void;
}

const emitWithPromise = (event: "click") => {
  return new Promise<void>((resolve) => {
    const callback = () => {
      resolve(); // Simply resolve the promise when the callback is invoked
    };

    emit(event, callback); // Emit the event with the callback
  });
};

function sleep(ms: number | undefined) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const onClick = async () => {
  if (enabled.value) {
    loading.value = true;
    try {
      await nextTick();
      await emitWithPromise("click");
    } finally {
      loading.value = false;

      if (props.enableCompleteText) {
        showCompleteText.value = true;
        await sleep(2000);
        showCompleteText.value = false;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vc-button {
  --min-w: var(--vc-button-min-width, auto);

  $colors: primary, secondary, success, info, neutral, warning, danger, accent;

  $prepend: "";
  $append: "";
  $icon: "";
  $truncate: "";
  $disabled: "";
  $loading: "";
  $loaderIcon: "";
  $noWrap: "";

  @apply relative inline-block rounded-md border select-none text-center;

  &--truncate {
    $truncate: &;
  }

  &--disabled {
    $disabled: &;
  }

  &--loading {
    $loading: &;
  }

  &--icon {
    $icon: &;
  }

  &--full-width {
    @apply w-full;
  }

  &--no-wrap {
    $noWrap: &;
  }

  &__loader {
    @apply hidden;

    #{$loading} & {
      @apply absolute inset-0 flex justify-center items-center;
    }
  }

  &__loader-icon {
    $loaderIcon: &;

    @apply block rounded-full animate-spin;

    .circular-loader {
      -webkit-animation: rotate 2s linear infinite;
      animation: rotate 2s linear infinite;
      height: 100%;
      -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }

    .loader-path {
      stroke-dasharray: 150, 200;
      stroke-dashoffset: -10;
      -webkit-animation:
        dash 1.5s ease-in-out infinite,
        color 6s ease-in-out infinite;
      animation:
        dash 1.5s ease-in-out infinite,
        color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }

  &:not(#{$icon}) {
    @apply min-w-[--min-w];
  }

  &--size {
    &--xs {
      --line-height: 0.875rem;

      @apply px-2.5 py-1 text-xs/[--line-height] font-bold;

      &#{$icon} {
        @apply px-1;
      }

      & #{$loaderIcon} {
        @apply border-[3px] w-3.5 h-3.5;
      }
    }

    &--sm {
      --line-height: 1rem;

      @apply text-xs/[--line-height];
      @apply p-2 uppercase font-semibold;

      & #{$loaderIcon} {
        @apply border-[3px] w-4 h-4;
      }
    }

    &--md {
      --line-height: 1.25rem;

      @apply text-sm/[--line-height];
      @apply p-2.5 font-semibold;

      & #{$loaderIcon} {
        @apply border-[3px] w-5 h-5;
      }
    }

    &--lg {
      --line-height: 1.5rem;

      @apply text-base/[--line-height];
      @apply px-3.5 py-2.5 font-semibold;

      & #{$loaderIcon} {
        @apply border-[3px] w-6 h-6;
      }
    }

    &--auto {
      --line-height: 1.5rem;

      @apply text-base/[--line-height];
      @apply px-6 py-3 font-semibold;

      & #{$loaderIcon} {
        @apply border-[3px] w-6 h-6;
      }
    }

    &--customTL {
      --line-height: 1.5rem;

      @apply px-4 py-2 text-sm/[--line-height];

      & #{$loaderIcon} {
        @apply border-[3px] w-6 h-6;
      }
    }
  }

  @each $color in $colors {
    &--color--#{$color} {
      &:focus {
        @apply outline-[--color-neutral-a7] outline-1 #{!important};
      }

      &:not([class*="--solid--"]) #{$loaderIcon} {
        @apply border-[--color-#{$color}-100] border-r-[--color-#{$color}-500];
      }
    }

    &--solid--#{$color} {
      @apply bg-[--color-#{$color}-500]
      border-[--color-#{$color}-500]
      text-[--color-additional-50];

      &:hover:not(#{$loading}, #{$disabled}) {
        @apply bg-[--color-#{$color}-700]
        border-[--color-#{$color}-700];
      }

      & #{$loaderIcon} {
        @apply border-[--color-#{$color}-200] border-r-[--color-additional-50];
      }
    }

    &--no-border--#{$color} {
      @apply bg-additional-50 text-[--color-#{$color}-500] border-additional-50;

      &:hover:not(#{$loading}, #{$disabled}) {
        @apply bg-[--color-#{$color}-100] border-[--color-#{$color}-100] text-[--color-#{$color}-700];
      }
    }

    &--outline--#{$color} {
      @apply bg-[--color-additional-50] text-[--color-#{$color}-500] border-current;

      &:hover:not(#{$loading}, #{$disabled}) {
        @apply text-[--color-#{$color}-700];
      }
    }

    &--outlineTL--#{$color} {
      @apply bg-[--color-additional-50] border-[--color-#{$color}-300];

      &:hover:not(#{$loading}, #{$disabled}) {
        @apply border-[--color-#{$color}-500];
      }
    }

    &--no-background--#{$color} {
      @apply bg-transparent text-[--color-#{$color}-500] border-transparent;

      &:hover:not(#{$loading}, #{$disabled}) {
        @apply text-[--color-#{$color}-700];
      }
    }
  }

  &:disabled#{$disabled}:not(#{$loading}) {
    &[class*="--solid--"] {
      @apply bg-[--color-neutral-a5] border-[--color-neutral-a5] text-[--color-neutral-a3] cursor-not-allowed;
    }

    &[class*="--no-border--"] {
      @apply bg-[--color-neutral-50] border-[--color-neutral-50] text-[--color-neutral-400];
    }

    &[class*="--outline--"] {
      @apply text-[--color-neutral-400] border-[--color-neutral-300];
    }

    &[class*="--no-background--"] {
      @apply text-[--color-neutral-400];
    }
  }

  &__content {
    @apply grid grid-flow-col justify-center;

    --vc-icon-size: var(--line-height);

    #{$loading} & {
      @apply invisible;
    }
  }

  &__slot {
    @apply max-w-full flex items-center;

    word-break: break-word;

    #{$truncate} & {
      @apply grow min-w-0 truncate;
    }

    #{$noWrap} & {
      @apply whitespace-nowrap;
    }

    &:empty {
      @apply hidden;
    }
  }

  &__prepend {
    @apply flex items-center;
    $prepend: &;

    &:empty {
      @apply hidden;
    }
  }

  &__append {
    $append: &;

    &:empty {
      @apply hidden;
    }
  }

  &__icon {
    #{$prepend} & {
      @apply me-2;
    }

    #{$append} & {
      @apply ms-2;
    }
  }

  &:focus {
    @apply outline-0;
  }

  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: currentColor;
    }
    40% {
      stroke: currentColor;
    }
    66% {
      stroke: currentColor;
    }
    80%,
    90% {
      stroke: currentColor;
    }
  }
  @keyframes color {
    0% {
      stroke: currentColor;
    }
    40% {
      stroke: currentColor;
    }
    66% {
      stroke: currentColor;
    }
    80%,
    90% {
      stroke: currentColor;
    }
  }

  &--link {
    @apply bg-transparent border-0 p-0 text-[--color-link] hover:text-[--color-neutral-a1] rounded-none;

    &:hover {
      @apply bg-transparent border-transparent;
    }
  }

  &--custom {
    border-color: var(--custom-color);
    color: var(--custom-color);
    @apply text-xs font-medium px-5 py-2.5 bg-white;

    &:hover:not(.vc-button--loading, .vc-button--disabled) {
      border-color: var(--custom-hover-color);
      color: var(--custom-hover-color);
    }
  }
}
</style>
