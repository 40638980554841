/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
const DEBOUNCE_MS = 300;
export const debouncedFunction = debouncePromise((items: any[]) => Promise.resolve(items), DEBOUNCE_MS);

function debouncePromise<T extends (...args: any[]) => any>(
  fn: T,
  time: number,
): (...args: Parameters<T>) => Promise<ReturnType<T>> {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return function debounced(...args: Parameters<T>): Promise<ReturnType<T>> {
    if (timer) {
      clearTimeout(timer);
    }

    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}
