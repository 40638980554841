<template>
  <dialog
    ref="dialogRef"
    :class="[dialogClass, dialogMobileClass, { 'flyout-right': isFlyout }]"
    :inert="!isOpen"
    :style="dialogWidthStyle"
  >
    <header :class="headerClass">
      <slot name="title"></slot>
      <button type="button" class="dialog-close" @click="closeDialog">
        <VcIcon
          class="text-[var(--color-neutral-a1)] transition-all hover:text-[var(--color-primary-a1)]"
          name="close"
          :size="20"
        />
      </button>
    </header>
    <div :class="contentClass">
      <slot name="content"></slot>
    </div>
    <footer v-if="hasFooterSlot" :class="footerClass">
      <slot name="footer"></slot>
    </footer>
  </dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, defineEmits, defineExpose, computed, getCurrentInstance } from "vue";
const emit = defineEmits<{
  open: [];
  close: [];
}>();

const props = withDefaults(
  defineProps<{
    dialogClass?: string;
    dialogMobileClass?: string;
    headerClass?: string;
    contentClass?: string;
    footerClass?: string;
    isFlyout?: boolean;
    dialogWidth?: string;
  }>(),
  {
    dialogClass: "",
    dialogMobileClass: "",
    headerClass: "",
    contentClass: "",
    footerClass: "",
    isFlyout: false,
    dialogWidth: "",
  },
);
const instance = getCurrentInstance();
const hasFooterSlot = computed(() => !!instance?.slots.footer?.());
const isOpen = ref(false);
const isMouseDownInside = ref(false);
const dialogRef = ref<HTMLDialogElement | null>(null);

const dialogWidthStyle = computed(() => {
  if (!props.dialogWidth) {
    return;
  }

  const dialogWidthValue = props.dialogWidth === "" ? "400px" : props.dialogWidth;
  return `--dialog-width: ${dialogWidthValue}`;
});

const openDialog = () => {
  if (!isOpen.value && dialogRef.value) {
    isOpen.value = true;
    dialogRef.value.showModal();
    emit("open");
  }
};
const closeDialog = () => {
  if (isOpen.value && dialogRef.value) {
    dialogRef.value.close();
    isOpen.value = false;
    emit("close");
  }
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  dialogRef.value?.addEventListener("mousedown", (event) => {
    // Check if the mousedown event started inside the dialog
    if (event.target !== dialogRef.value) {
      isMouseDownInside.value = true;
    }
  });

  dialogRef.value?.addEventListener("mouseup", (event) => {
    // Check if the mouseup event ended outside the dialog
    if (event.target === dialogRef.value && !isMouseDownInside.value) {
      closeDialog();
    }
    // Reset the flag
    isMouseDownInside.value = false;
  });
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    closeDialog();
  }
};

// Expose methods to parent components
defineExpose({
  isOpen,
  openDialog,
  closeDialog,
});
</script>

<style scoped lang="scss">
dialog {
  @apply lg:max-w-full max-w-[calc(100%-32px)] border border-solid border-[--color-neutral-a4] p-0 bg-white shadow-[0px_5px_12px_0px_rgba(0,0,0,0.1)] rounded-[20px] text-[--color-neutral-a1] flex flex-col fixed top-0 bottom-0 left-0 right-0 m-auto z-10 md:w-[--dialog-width];
  transition: opacity 0.3s;
  &[open] {
    animation: slide-in-up 0.3s forwards;
  }
  &:not([open]) {
    @apply pointer-events-none opacity-0;
    animation: slide-out-down 0.3s ease forwards;
  }
  dialog {
    &:not([open]) {
      @apply h-0;
    }
  }
  &.flyout-right {
    animation: slide-in-left 0.3s forwards;
    @apply left-auto;
    &[open] {
      animation: slide-in-left 0.3s forwards;
    }
    &:not([open]) {
      animation: slide-out-right 0.3s ease forwards;
    }
    &.mobile-full {
      @apply min-h-full min-w-[320px] max-h-[100dvh] h-full rounded-none md:min-h-[inherit] md:min-w-[inherit] overflow-hidden;
    }
  }

  &[dialog-mode="large"] {
    @apply w-[930px] min-h-[100px];
  }
  &[dialog-mode="medium"] {
    @apply w-[700px] min-h-[100px];
  }
  &[dialog-mode="small"] {
    @apply md:w-[520px] min-h-[100px] w-full;
  }
  &[dialog-mode="x-small"] {
    @apply md:w-[440px] min-h-[100px] w-full;
  }
  &.mobile-full {
    @apply min-h-full min-w-full max-h-[100dvh] rounded-none md:min-h-[inherit] md:min-w-[inherit] md:rounded-[20px];
  }

  &::backdrop {
    @apply bg-[rgba(0,0,0,0.5)];
  }
  header {
    @apply sticky top-0 left-0 right-0 z-10 px-[25px] py-5 bg-[--color-neutral-a6] w-full flex items-center justify-between;
  }
  footer {
    @apply sticky bottom-0 left-0 right-0 z-10 px-[25px] py-5 w-full flex justify-between bg-white;
  }
  .dialog-close {
    @apply absolute right-5 top-auto z-20 w-5 h-5 outline-none flex;

    svg {
      @apply w-full h-full;
    }
  }
  &.large-close {
    .dialog-close {
      @apply md:right-7 md:top-7 top-3 right-4 size-10 rounded-full bg-white z-20 md:w-6 md:h-6 w-5 h-5;
    }
  }
  .no-background {
    @apply bg-transparent;

    .dialog-close {
      @apply md:right-6 right-4 md:top-5 top-3;
    }
  }
  .no-padding {
    @apply p-0;
  }
  .dialog-content {
    @apply overflow-y-auto p-[25px];
  }
}

@-webkit-keyframes slide-in-up {
  0% {
    transform: translateY(100%);
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(100%);
  }
}
@-webkit-keyframes slide-out-down {
  to {
    transform: translateY(100%);
  }
}

@keyframes slide-out-down {
  to {
    transform: translateY(100%);
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    transform: translateX(100%);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100%);
  }
}
@-webkit-keyframes slide-out-right {
  to {
    transform: translateX(100%);
  }
}

@keyframes slide-out-right {
  to {
    transform: translateX(100%);
  }
}
</style>
